function findNode(data, nodeid, parentOpen) {
  const paths = {};
  const paths2 = {};
  let find = null;
  let count = 0;
  let countAll = 0;

  function nodes(list, parentid, parentOpen) {
    list.forEach((item, key) => {
      if (find === null) {
        count = count + 1;
        countAll = countAll + 1;
      } else {
        if (parentid === 'root' || paths[parentid] || parentOpen) {
          countAll = countAll + 1;
        }
      }

      if (item.id === nodeid || item.alias === nodeid) {
        find = item;
      }

      if (item.children !== undefined) {
        if (find === null) {
          paths[item.id] = item;
          paths2[item.id] = true;
        }
        nodes(item.children, item.id, item.expanded);
        if (find === null) {
          if (!item.expanded) {
            count = count - item.children.length;
            countAll = countAll - item.children.length
          }
          delete paths[item.id];
          delete paths2[item.id];
        }
      }
    });
  }
  nodes(data, 'root', false);
  if (find) {
   find.paths = paths;
   find.paths2 = paths2;
   find.scrollPoint = (count - 1) * 36.5 + 64 + 16;
   find.windowHeight = countAll * 36.5 + 64 + 16; 
  }
  
  return find;
}

export default findNode;