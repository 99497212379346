import React from 'react';

import { createSelector } from 'reselect';
import { connect } from 'react-redux';

import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { drawerWidth } from '../constants';

import { setTree, setSelect } from '../actions/menu';
import core from '../core';

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    background: '#fafafa',
  },
  toolbar: theme.mixins.toolbar,
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    letterSpacing: 0,
    justifyContent: 'flex-start',
    textTransform: 'none',
    width: '100%',
  },
  buttonLeaf: {
    letterSpacing: 0,
    justifyContent: 'flex-start',
    textTransform: 'none',
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  label: {
    textAlign: 'left',
  }
}));

function MenuItem({ params, depth, select, tree, handleClick }) {
  const classes = useStyles();
  const style = {
    paddingLeft: 8 * (3 + 2 * depth),
  };
  
  if (params.draft) {
    return null;
  }

  if (params.children) {
    return (
      <ListItem className={classes.item} disableGutters>
        <Button
          classes={{
            root: classes.button,
            label: classes.label,
          }}
          style={style}
          onClick={() => handleClick(params, tree)}
        >
          {params.title}
        </Button>
        <Collapse in={tree[params.id]} timeout="auto" unmountOnExit>
          <List>
            {params.children
              .map((i) => <MenuItem key={i.id} params={i} tree={tree} select={select} handleClick={handleClick} depth={depth + 1} />)}
          </List>
        </Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem className={classes.itemLeaf} disableGutters>
      <Button
        naked="true"
        className={clsx(classes.label, classes.buttonLeaf, `depth-${depth}`, (select === params.id || select === params.alias) ? `drawer-active ${classes.active}` : '')}
        style={style}
        onClick={() => handleClick(params, tree)}
      >
        {params.title}
      </Button>
    </ListItem>
  );
}

function Menu({ history, menu, mobileOpen, handleDrawerToggle, dispatch }) {
  const classes = useStyles();
  const theme = useTheme();

  function handleClick(item, tree) {
    dispatch(setTree({ ...tree, [item.id]: !tree[item.id] }));
    dispatch(setSelect(item.id));

    if (item.children === undefined) {
      core.isNavUser = true;
      history.push('/' + core.cache.lang + '/' + item.id, { user: true });
    }
  }

  const drawer = (
    <div id="nav">
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {menu.list.map((i) => <MenuItem key={i.id} tree={menu.tree} select={menu.select} handleClick={handleClick} params={i} depth={0} />)}
      </List>
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          container={null}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}

const selector = createSelector(
  (state) => state.menu,
  (menu) => ({ menu }),
);


export default (connect(selector, null, null, { pure: true }))(Menu);
