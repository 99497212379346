import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';


import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';

import './index.css';

import theme from './theme';
import App from './App';

import store from './store/index';

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);
