export const SET_DATA = 'SET_DATA';
export const SET_TREE = 'SET_TREE';
export const SET_SELECT = 'SET_SELECT';
export const SET_MENU = 'SET_MENU';


export function setData(data) {
  return {
    type: SET_DATA,
    data,
  };
}


export function setMenu(data) {
  return {
    type: SET_MENU,
    data,
  };
}

export function setTree(tree) {
  return {
    type: SET_TREE,
    tree,
  };
}

export function setSelect(id) {
  return {
    type: SET_SELECT,
    id,
  };
}
