export const SET_NAV = 'SET_NAV';
export const SET_SELECT_NAV = 'SET_SELECT_NAV';

export function setNav(data) {
  return {
    type: SET_NAV,
    data,
  };
}

export function setSelectItemNav(id) {
  return {
    type: SET_SELECT_NAV,
    id,
  };
}
