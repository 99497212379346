import {
  SET_NAV,
  SET_SELECT_NAV,
} from '../actions/nav';


const initialState = {
  select: null,
  data: [],
};

function nav(state = initialState, action) {
  switch (action.type) {
    case SET_NAV:
      return { ...state, data: action.data };
    case SET_SELECT_NAV:
      return { ...state, select: action.id };
    default:
      return state;
  }
}


export default nav;
