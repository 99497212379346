

function inIframe() {
  try {
      return window.self !== window.top;
  } catch (e) {
      return true;
  }
}

function auth(params) {
  if (!window.localStorage.getItem('token')) {
    core.handleAuth(params);
  }
}

const core = {
  auth,
  isFrame: inIframe(),
  cache: {
    lang: 'ru',
    token: window.localStorage.getItem('token'),
  },
  isNavUser: false,
}


export default core;