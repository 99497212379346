import core from './core';

function getSource() {
  if (window.location.host.indexOf('house') !== -1) {
    return 'intrahouse';
  }
  if (window.location.host.indexOf('opc') !== -1) {
    return 'intraopc';
  }
  return 'intrascada';
}

function req(data) {
  const source = getSource();
  let url = (data.params && data.params.id) ? 
  `/restapi/${data.req}?source=${source}&lang=${core.cache.lang}&id=${data.params.id}` : 
  `/restapi/${data.req}?source=${source}&lang=${core.cache.lang}`;
  if (data.params && data.params.str) {
    url = `/restapi/${data.req}?source=${source}&lang=${core.cache.lang}&str=${data.params.str}`;
  }
  return fetch(url)
    .then((r) => {
      if (r.status === 200) {
        return r.json();
      }
    })
    .then(res => {
      return res.data;
    });
}

function upload(id, data) {
  return fetch('upload/' + id, {
    method: 'post',
    body: data,
  })
    .then((r) => {
      if (r.status === 200) {
        return r.json();
      }
    })
    .then(res => {
      return res.data;
    });
}

const api = {
  req,
  upload,
};

export default api;
