import {
  SET_DATA,
  SET_MENU,
  SET_TREE,
  SET_SELECT,
} from '../actions/menu';


const initialState = {
  list: [],
  tree: {},
  select: null,
};

function menu(state = initialState, action) {
  switch (action.type) {
    case SET_DATA:
      return { ...state, ...action.data };
    case SET_MENU:
      return { ...state, list: action.data };
    case SET_TREE:
      return { ...state, tree: action.tree };
    case SET_SELECT:
      return { ...state, select: action.id };
    default:
      return state;
  }
}


export default menu;
