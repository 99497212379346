import React, { Component } from 'react';

import clsx from 'clsx';

import NProgress from 'nprogress';

import Divider from '@material-ui/core/Divider';

import MarkdownDocsContents from '../../../components/MarkDown/MarkdownDocsContents';
import MarkdownElement from '../../../components/MarkDown/MarkdownElement';
import AppTableOfContents from '../../../components/MarkDown/AppTableOfContents';
import findNode from '../../../components/tools';

import { withStyles } from '@material-ui/core/styles';


import { setData, setSelect } from '../../../actions/menu';
import store from '../../../store';

import api from '../../../api';
import core from '../../../core';

let init = true;
let autoscroll = true;


const styles = (theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingLeft: 8,
    paddingTop: 50,
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 32,
      paddingTop: 96,
      maxWidth: 'calc(100% - 435px)',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 32,
      paddingTop: 96,
      maxWidth: 'calc(100% - 175px - 260px)',
    },
    backgroundColor: '#fff',
  },
  content2: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingLeft: 32,
    paddingTop: 24,
    maxWidth: '100%',
    backgroundColor: '#fff',
  },
  button: {
    margin: theme.spacing(1),
  },
  footer: {
    width: '100%',
    marginTop: theme.spacing(12),
  },
  pagination: {
    margin: theme.spacing(3, 0, 4),
    display: 'flex',
    justifyContent: 'space-between',
    height: 42,
  },
  markdownElement: {
    marginBottom: theme.spacing(2),
    padding: '0px 8px',
    paddingRight: 16,
  },
  markdownElementBlog: {
    maxWidth: 700,
    margin: 'auto',
    padding: 0,
    fontSize: theme.typography.pxToRem(18),
    fontFamily: `Roboto Slab, ${theme.typography.fontFamily}`,
    fontWeight: 300,
    '& p, & ul, & ol': {
      lineHeight: 1.7,
    },
    '& strong': {
      fontWeight: 400,
      fontFamily: theme.typography.fontFamily,
    },
    '& img': {
      display: 'block',
      margin: 'auto',
    },
    '& .blog-description': {
      fontSize: theme.typography.pxToRem(14),
      textAlign: 'center',
    },
  },
});

const TITLES = {
  ru: 'Документация',
  en: 'Documentation',
}

const params = window.location.pathname.split('/');
const lang = params[1] || 'ru';

let title = TITLES[lang];

class Content extends Component {
  state = { data: '' };

  componentDidMount() {
    this.getData(this.props.match.params.id, true);
  }

  componentWillReceiveProps(nextProps) {
    const isNavUser = core.isNavUser;
    core.isNavUser = false;
    if (this.props.match.params.lang !== nextProps.match.params.lang || this.props.match.params.id !== nextProps.match.params.id) {
      this.getData(nextProps.match.params.id, isNavUser);
    }
  }

  getData = (id, isNavUser) => {
    if (id) {
      if (init === false && autoscroll === true) {
        autoscroll = false;
      }
      NProgress.start();
      api.req({ type: 'userdata', req: 'page', params: { id } })
        .then((text) => {
          const data = typeof text === 'string' ? text : text.data;
          NProgress.done();
          if (init === false) {
            let foundTitle = false;
            const rows = data.split('\n');
            rows.forEach(str => {
              const indexTitle = str.indexOf('#');
              if (foundTitle === false && indexTitle != -1) {
                foundTitle = true;
                title = str.replace(/\#/g, '').trim();
              }
            });
            document.body.scrollIntoView()
            document.title = title;

            if (!isNavUser) {
              store.dispatch(setSelect(id));
              const nav = document.getElementById('nav');
              if (nav) {
                const parent = nav.parentNode;
                const node = findNode(store.getState().menu.list, id);
                
                store.dispatch(setData({
                  tree: node.paths2,
                }));
  
                if (parent) {
                  const scrollTop = node.scrollPoint - ((parent.clientHeight - 5) / 2) - 9;
                  setTimeout(() => {
                    parent.scrollTop = scrollTop
                  }, 200)
                }
              }
            }
          }
          
          this.setState({ data: data || '' }, () => {
            if (init) {
              init = false;
              for (let img of document.images) {
                img.onload = () => {
                  const element = document.getElementById(window.location.hash.slice(1));
                  if (autoscroll && element) {
                    element.scrollIntoView() 
                  }
                };
              }
            }
          });
        })
        .catch(() => {
          NProgress.done();
        });
    }
  }
//   {core.isFrame ? <Toolbar history={history} /> : null}
  render({ classes, history, nav, dispatch, match, location } = this.props) {
    if (core.isFrame && this.state.data === '') {
      return null;
    }
    return (
      <MarkdownDocsContents markdown={this.state.data} markdownLocation={undefined}>
        {({ contents, markdownLocation }) => (
          <>
            <AppTableOfContents contents={contents} />
            <main className={core.isFrame ? classes.content2 : classes.content}>
              <MarkdownElement
                text={this.state.data}
                className={clsx(classes.markdownElement, { [classes.markdownElementBlog]: false })}
              />
              <footer className={classes.footer}>
                <Divider />
                <div className={classes.pagination} />
              </footer>
            </main>
          </>
        )}
      </MarkdownDocsContents>
    );
  }
}


export default withStyles(styles)(Content);
