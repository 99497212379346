import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NoSsr from '@material-ui/core/NoSsr';

import TranslateIcon from '@material-ui/icons/Translate';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

import Portal from '@material-ui/core/Portal';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import Typography from '@material-ui/core/Typography';

import MarkdownElementSearch from '../components/MarkDown/MarkdownElementSearch';

import Backdrop from '@material-ui/core/Backdrop';

import NProgress from 'nprogress';

import { alpha, withStyles } from '@material-ui/core/styles';

import core from '../core';
import api from '../api';

const LANGUAGES_LABEL = [
  {
    code: 'ru',
    text: 'Русский',
  },
  {
    code: 'en',
    text: 'English',
  },
];

const LANGUAGES_TEXT = LANGUAGES_LABEL.reduce((p, c) => ({ ...p, [c.code]: c.text}), {});

const classes = (theme) => ({
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  search: {
    zIndex: 2,
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
    marginRight: 22,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputRootMobile: {
    color: '#1A2027',
    height: 56,
    paddingLeft: 25,
    fontSize: '1.2em',
    width: '80%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  inputInputMobile: {
    // padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',

  },
  sectionMobile: {
    // width: 200,
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
});

const styles = {
  backdrop: {
    zIndex: 1,
  },
  backdropMobile: {
    zIndex: 99999,
    background: '#fff',
  },
  paper: {
    overflow: 'auto', 
    minWidth: 500, 
    maxWidth: 700, 
    maxHeight: 'calc(100vh - 100px)', 
    padding: '0 16px 24px' 
  },
  empty: {
    marginTop: 24,
  },
  emptyMobile: {
    color: 'rgba(0,0,0,0.87)',
    marginTop: 14,
    marginLeft: 12,
  },
  searchItem: {
    width: '100%', 
    marginTop: 8,
  },
  searchItemMobile: {
    width: '100%', 
    padding: '0px 12px', 
    marginTop: 8,
  },
  searchItemTitle: {
    color: 'rgba(0, 0, 0, 0.87)', 
    fontWeight: 400,
  },
  searchItemBody: {
    paddingLeft: 12, 
    paddingBottom: 8, 
  },
  searchItemButton: {
    textAlign: 'left', 
    justifyContent: 'start', 
    textTransform: 'none', 
    // height: 53.5,
    overflow: 'hidden',
  },
  searchItemME: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 400,
  }
};

const modifiers = {
  offset: {
    offset: "0,10,0,0"
  }
};

class SectionMobile extends Component {
  state = { loading: false, searchText: '', data: [] }
  
  inputBaseRef = (e) => {
    this.inputBase = e;
  }

  searchData = (target, str) => {
    NProgress.start();

    this.disableScroll(true);
    this.inputBase.focus();
    this.setState({ loading: true, anchorBackdrop: target });

    api
      .req({ type: 'userdata', req: 'search', params: { str } })
      .then(data => {
        NProgress.done();
        this.setState({ loading: false, data: data || [] });
      })
      .catch(() => {
        NProgress.done();
        this.setState({ loading: false, data: [] });
      });
  }

  disableScroll = (value) => {
    document.body.style.overflow = value ? 'hidden' : 'auto';
  }

  handleClickLangButton = (e) => {
    this.setState({ anchorLang: e.currentTarget });
  }

  handleClickCloseLangMenu = () => {
    this.setState({ anchorLang: null });
  }

  handleClickSelectLangMenu = ({ code }) => {
    this.setState({ anchorLang: null });
    this.props.onChangeLang(code);
  }

  handleClickSearchButton = (e) => {
    this.disableScroll(true);
    this.setState({ anchorBackdrop: e.currentTarget }, () => {
      if (this.state.searchText == false) {
        this.inputBase.focus();
      }
    });
  }

  handleCloseBackdrop = () => {
    this.disableScroll(false);
    this.setState({ anchorBackdrop: null, loading: false });
  }

  handleChangeInputBase = (e) => {
    e.preventDefault();

    const value = e.target.value;
    const target = e.currentTarget;

    this.setState({ loading: true, searchText: value });
    
    if (value) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => this.searchData(target, value), 200);
    } else {
      clearTimeout(this.timer);
      this.setState({ loading: false, data: [] });
      // this.disableScroll(false);
      // this.setState({ anchorBackdrop: null });
    }
  }

  handleClickSearchItem = ({ id }) => {
    this.disableScroll(false);
    this.setState({ anchorBackdrop: null });

    this.props.history.push('/' + core.cache.lang + '/' + id);
  }

  renderSearchResult = () => {
    const title = core.cache.lang === 'ru' ? 'Загрузка...' : 'Loading...';
    const title2 = core.cache.lang === 'ru' ? 'Не найдено результатов для' : 'No results found for query ';
  
    if (this.state.loading && this.state.data.length === 0) {
      return <div style={styles.emptyMobile}>{title}</div>
    }
  
    if (this.state.searchText && this.state.data.length === 0) {
      return <div style={styles.emptyMobile}>{title2 + ' "' +  this.state.searchText + '"'}</div>
    }
  
    return this.state.data.map(item => {
      return (
        <div key={item.id + item.matched} style={styles.searchItemMobile}>
          <Typography 
            noWrap 
            variant="subtitle1" 
            style={styles.searchItemTitle}
          >
            {item.title}  
          </Typography>
          <div style={styles.searchItemBody}>
            <Button 
              fullWidth
              size="small"
              style={styles.searchItemButton}
              onClick={() => this.handleClickSearchItem(item)}  
            >
              <MarkdownElementSearch
                style={styles.searchItemME} 
                text={item.matched} 
              />
            </Button>
          </div>
          <Divider />
        </div>
      );
    });
  }

  render({ classes, lang } = this.props) {
    return (
      <div className={classes.sectionMobile}>
         <IconButton 
            aria-label="search" 
            color="inherit"
            onClick={this.handleClickSearchButton}
          >
            <SearchIcon />
          </IconButton>
          <Button
            color="inherit" 
            aria-controls="simple-menu" 
            aria-haspopup="true" 
            onClick={this.handleClickLangButton}
            startIcon={<TranslateIcon />}
            endIcon={<ExpandMoreIcon />}
          >
            {LANGUAGES_TEXT[lang]}
          </Button>
          <NoSsr>
            <Menu
              keepMounted
              anchorEl={this.state.anchorLang}
              open={Boolean(this.state.anchorLang)}
              onClose={this.handleClickCloseLangMenu}
            >
              {LANGUAGES_LABEL.map(i => 
                <MenuItem 
                  key={i.text} 
                  onClick={() => this.handleClickSelectLangMenu(i)}
                >
                  {i.text}
                </MenuItem>
              )}
            </Menu>
          </NoSsr>
          <Portal>
            <Backdrop
              open={Boolean(this.state.anchorBackdrop)} 
              style={styles.backdropMobile}  
            >
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: '0px 10px', flexShrink: 0 }}>
                  <SearchIcon style={{ color: '#607d8b' }}/>
                  <InputBase
                    inputRef={this.inputBaseRef}
                    value={this.state.searchText}
                    placeholder={core.cache.lang === 'ru' ? ' Поиск...' : 'Search…'}
                    classes={{
                      root: classes.inputRootMobile,
                      input: classes.inputInputMobile,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={this.handleChangeInputBase}
                  />
                  <Button variant='outlined' onClick={this.handleCloseBackdrop}>
                    {this.props.lang === 'ru' ? 'Выход' : 'ESC'}
                  </Button>
                </div>
                <Divider />
                <div style={{ overflow: 'auto', height: 'calc(100% - 67px)' }}>
                  {this.renderSearchResult()}
                </div>
              </div>
            </Backdrop>
          </Portal>
      </div>
    );
  }
}

class SectionDesktop extends Component {
  state = { loading: false, searchText: '', data: [] }
  
  inputBaseRef = (e) => {
    this.inputBase = e;
  }

  searchData = (target, str) => {
    NProgress.start();

    this.disableScroll(true);
    this.inputBase.focus();
    this.setState({ loading: true, anchorBackdrop: target });

    api
      .req({ type: 'userdata', req: 'search', params: { str } })
      .then(data => {
        NProgress.done();
        this.setState({ loading: false, data: data || [] });
      })
      .catch(() => {
        NProgress.done();
        this.setState({ loading: false, data: [] });
      });
  }

  disableScroll = (value) => {
    document.body.style.overflow = value ? 'hidden' : 'auto';
  }

  handleChangeInputBase = (e) => {
    e.preventDefault();

    const value = e.target.value;
    const target = e.currentTarget;

    this.setState({ searchText: value });
    
    if (value) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => this.searchData(target, value), 200);
    } else {
      clearTimeout(this.timer);

      this.disableScroll(false);
      this.setState({ anchorBackdrop: null });
    }
  }

  handleClickInputBase = (e) => {
    if (this.state.searchText) {
      this.disableScroll(true);
      this.setState({ anchorBackdrop: e.currentTarget });
      this.inputBase.focus();
    }
  }

  handleClickLangButton = (e) => {
    this.setState({ anchorLang: e.currentTarget });
  }

  handleClickCloseLangMenu = () => {
    this.setState({ anchorLang: null });
  }

  handleClickSelectLangMenu = ({ code }) => {
    this.setState({ anchorLang: null });
    this.props.onChangeLang(code);
  }

  handleCloseBackdrop = () => {
    this.disableScroll(false);
    this.setState({ anchorBackdrop: null });
  }

  handleClickSearchItem = ({ id }) => {
    this.disableScroll(false);
    this.setState({ anchorBackdrop: null });

    this.props.history.push('/' + core.cache.lang + '/' + id);
  }

  renderSearchResult = () => {
    const title = core.cache.lang === 'ru' ? 'Загрузка...' : 'Loading...';
    const title2 = core.cache.lang === 'ru' ? 'Не найдено результатов для' : 'No results found for query ';
  
    if (this.state.data.length === 0 && this.state.loading) {
      return <div style={styles.empty}>{title}</div>
    }
  
    if (this.state.data.length === 0) {
      return <div style={styles.empty}>{title2 + ' "' +  this.state.searchText + '"'}</div>
    }
  
    return this.state.data.map(item => {
      return (
        <div key={item.id + item.matched} style={styles.searchItem}>
          <Typography 
            noWrap 
            variant="subtitle1" 
            style={styles.searchItemTitle}
          >
            {item.title}  
          </Typography>
          <div style={styles.searchItemBody}>
            <Button 
              fullWidth
              size="small"
              style={styles.searchItemButton}
              onClick={() => this.handleClickSearchItem(item)}  
            >
              <MarkdownElementSearch
                style={styles.searchItemME} 
                text={item.matched} 
              />
            </Button>
          </div>
          <Divider />
        </div>
      );
    });
  }

  render({ classes, lang } = this.props) {
    return (
      <div className={classes.sectionDesktop}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            inputRef={this.inputBaseRef}
            value={this.state.searchText}
            placeholder={core.cache.lang === 'ru' ? ' Поиск...' : 'Search…'}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
            onChange={this.handleChangeInputBase}
            onClick={this.handleClickInputBase}
          />
        </div>
        <Button
          color="inherit" 
          aria-controls="simple-menu" 
          aria-haspopup="true" 
          onClick={this.handleClickLangButton}
          startIcon={<TranslateIcon />}
          endIcon={<ExpandMoreIcon />}
        >
          {LANGUAGES_TEXT[lang]}
        </Button>
        <NoSsr>
          <Menu
            keepMounted
            anchorEl={this.state.anchorLang}
            open={Boolean(this.state.anchorLang)}
            onClose={this.handleClickCloseLangMenu}
          >
            {LANGUAGES_LABEL.map(i => 
              <MenuItem 
                key={i.text} 
                onClick={() => this.handleClickSelectLangMenu(i)}
              >
                {i.text}
              </MenuItem>
            )}
          </Menu>
        </NoSsr>
        <Backdrop
          open={Boolean(this.state.anchorBackdrop)} 
          style={styles.backdrop}  
          onClick={this.handleCloseBackdrop}
        >
          <Popper
            disablePortal
            transition
            open={Boolean(this.state.anchorBackdrop)}
            anchorEl={this.state.anchorBackdrop}
            placement="bottom-end"
            modifiers={modifiers}
          >
            <Paper style={styles.paper}>
              {this.renderSearchResult()}
            </Paper>
          </Popper>
        </Backdrop>
      </div>
    );
  }
}

export default {
  SectionMobile: withStyles(classes)(SectionMobile),
  SectionDesktop: withStyles(classes)(SectionDesktop),
}