import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '../../../components/AppBar';
import Menu from '../../../components/Menu';
import findNode from '../../../components/tools';

import Content from './Content';

import { setMenu, setData } from '../../../actions/menu';
import store from '../../../store';

import api from '../../../api';
import core from '../../../core';


const styles = (theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#fff',
  },
});

const params = window.location.pathname.split('/');

const DEFAULT_LANGUAGE = params[1] ? params[1] : 'ru';

core.cache.lang = DEFAULT_LANGUAGE;

function getFirstChild(data) {
  let found = null;

  function search(list) {
    list.forEach(i => {
      if (i.children === undefined) {
        if (found === null) {
          found = i;
        }
      } else {
        search(i.children);
      }
    });
  }
  search(data);
  return found;
}


class Doc extends Component {
  state = { mobileOpen: false, lang: DEFAULT_LANGUAGE };

  componentDidMount() {
    if (!core.isFrame) {
      api.req({ type: 'getdata', req: 'tree' })
      .then((data) => {
        store.dispatch(setMenu(data));
        const params = this.props.history.location.pathname.split('/');
        
        if (data && !params[2]) {
          const child = getFirstChild(data);
          if (child) {
            core.isNavUser = true;
            this.props.history.push('/' + core.cache.lang + '/' + child.id);
            const node = findNode(data, params[2] || child.id);
     
            if (node && params) {
              store.dispatch(setData({
                tree: node.paths2,
                select: params[2] || child.id,
              }));
            }
          }
        } else {
          const nav = document.getElementById('nav');
          if (nav) {
            const parent = nav.parentNode;
            const node = findNode(data, params[2]);
            if (node && params) {
              store.dispatch(setData({
                tree: node.paths2,
                select: params[2],
              }));
              if (parent) {
                const scrollTop = node.scrollPoint - ((parent.clientHeight - 5) / 2) - 9;
                setTimeout(() => {
                  parent.scrollTop = scrollTop
                }, 200);
              }
            }
          }
        }
      });
    }
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }

  handleChangeLang = (code) => {
    core.cache.lang = code;
    this.setState({ lang: code });
    const params = this.props.history.location.pathname.split('/');
    
    if (params.length === 3) {
      core.isNavUser = true;
      this.props.history.push('/' + core.cache.lang + '/' + params[2]);
    }

    api.req({ type: 'getdata', req: 'tree' })
      .then((data) => {
        store.dispatch(setMenu(data));
      });
  }

  render({ classes, history } = this.props) {
    if (core.isFrame) {
      return (
        <>
          <div className={classes.root}>
            <Route path="/:lang/:id" component={Content} />
          </div>
        </>
      );
    }
    return (
      <div className={classes.root}>
        <AppBar  
          history={history} 
          lang={this.state.lang} 
          handleChangeLang={this.handleChangeLang} 
          handleDrawerToggle={this.handleDrawerToggle}
        />
        <Menu
          refDrawer={this.linked}
          history={history}
          mobileOpen={this.state.mobileOpen}
          handleDrawerToggle={this.handleDrawerToggle}
        />
        <Route path="/:lang/:id" component={Content} />
      </div>
    );
  }
}


export default withStyles(styles)(Doc);
