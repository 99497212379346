import { createStore, combineReducers } from 'redux';

import menu from '../reducers/menu';
import nav from '../reducers/nav';

const store = createStore(combineReducers({
  menu,
  nav,
}));

export default store;
